.verify-password {
    margin-top: -65px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.verify-password form {
    margin-top: 65px;
}
.verify-password .verify-password-header h3 {
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
    font-size: 30px;
    font-family: Ubbaha-Bold;
    line-height: 46px;
    letter-spacing: 0px;
    color: var(--main-color);
    text-transform: capitalize;
}
.verify-password .verify-password-header p {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    font-size: 15px;
    font-family: Ubbaha-Bold;
    line-height: 21px;
    letter-spacing: 0px;
    color: #333;
}
.verify-password form .btn {
    background: var(--main-color);
    border: 1px solid var(--main-color);
    color: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
    font-size: 18px;
    font-family: Ubbaha-Bold;
    line-height: 28px;
    letter-spacing: 0px;
    color: #fff;
    height: 46px;
    width: 100%;
}
.verify-password span {
    cursor: pointer;
}
.verify-password span p {
    text-decoration: underline;
    text-align: right;
    margin-bottom: 30px;
    font-size: 15px;
    font-family: Ubbaha-Bold;
    line-height: 21px;
    letter-spacing: 0px;
    color: #333;
}
.verify-password form .btn:active, .verify-password form .btn:hover, .verify-password form .btn:visited {
    background: var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
}
.verify-password form .form-control {
    border: 1px solid var(--main-color);
    margin-bottom: 20px;
    font-size: 18px;
    font-family: Ubbaha-Bold;
    line-height: 28px;
    letter-spacing: 0px;
    background: rgba(51, 51, 51, .5);
    border-radius: 8px;
    height: 46px;
    background-color: #FFF !important;
}
.verify-password form .verify-password-icon {
    margin-right: 5px;
}
.verify-password form .form-password {
    position: relative;
}
.verify-password form .password-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-10px,-50%);
    color: var(--main-color);
    cursor: pointer;
}

@media (max-width:991px) {
    .verify-password {
        padding: 30px 0;
        background-color: #fff;
    }
    .verify-password .verify-password-header h3 {
        margin-bottom: 15px;
        font-size: 30px;
        line-height: 46px;
    }
    .verify-password span p, .verify-password .append-text {
        margin-bottom: 25px;
    }
    .verify-password img {
        width: 30px;
        height: 30px;
    }
    .verify-password form .form-control, .verify-password form .btn {
        font-size: 15px;
        line-height: 20px;
        height: 40px;
    }
}
@media(max-width:767px) {
    .verify-password .form-button {
        margin-left: 0;
    }
}