.home {
    margin-top: -65px;
    padding: 0 !important;
    min-height: 100vh;
}
.home .home-gallery-container {
    padding: 0;
}
.home .home-gallery-container .row {
    z-index: 100;
  
}
.home .home-bg {
    padding: 60px;
}
.home .row-first {
    margin-top: 65px;
    position: absolute;
    padding-left: 60px;
    width: 100%;
    top: 50%;
    transform: translate(0,-50%);
}
.home .category-items .category-item {
    width: 130px;
    height: 130px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 0px 8px 5px #00000099;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .6s ease-in-out;
    overflow: hidden;
    z-index: 2;
}
.home .category-items .category-item p {
    color: #F5F5F5;
    font-family: Ubbaha-Bold;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0;
    text-align: center;
    transition: all .7s linear;
    position: relative;
    bottom: -110px;
    margin-bottom: 0;
}
.home .category-items .category-item:hover p {
    bottom: 0;
}
.home .home-slider {
    position: relative;
}
.home .home-slider .home-slider-footer {
    z-index: 1;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 120px;
    transform: matrix(-1, 0, 0, -1, 0, 0);
    background: transparent linear-gradient(180deg, #7C264B 0%, #7C264BB4 48%, #7C264B00 100%) 0% 0% no-repeat padding-box;
}

/*media*/
@media(max-width:1199px) {
    .home .home-bg  {
        padding: 30px;
    }
}
@media(max-width:991px) {
    .home-gallery-container {
        display: none;
    }
}
@media(max-width:767px) {
    .home {
        padding-top: 65px;
        min-height: auto;
    }
    .home .home-bg {
        background: none;
        padding: 60px 15px;
    }
    .home .category-items .category-item {
        width: 100%;
    }
    .home .category-items .category-item p {
        font-size: 16px;
    }   
}

/* Sweep To Top */
.hvr-sweep-to-top {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: .7s;
    transition-duration: .7s;
}
  .hvr-sweep-to-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000066;
    opacity: 1;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: .7s;
    transition-duration: .7s;
    -webkit-transition-timing-function: ease-in-out;
    transition-timing-function: ease-in-out;
}
.hvr-sweep-to-top:hover, .hvr-sweep-to-top:focus, .hvr-sweep-to-top:active {
    color: #F5F5F5;
}
.hvr-sweep-to-top:hover:before, .hvr-sweep-to-top:focus:before, .hvr-sweep-to-top:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
}

