.my-order-item .card {
    background: #FFF;
    box-shadow: 0px 0px 6px #00000080;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
}
.my-order-item  .card * {
    padding: 0;
}
.my-order-item  table tr {
    padding: 0 20px !important;
}
.my-order-item  table tr td {
    border: none;
}
.my-order-item  table tr td span {
    font-family: Ubbaha-Bold;
}
.my-order-item .card .card-title {
    font-size: 24px;
    line-height: 37px;
    letter-spacing: 0;
    font-family: Ubbaha-Bold;
    color: #333;
    margin-bottom: 30px;
    margin-top: 20px;
    padding-left: 10px;
}
.my-order-item .card .card-text, .my-order-item .card .card-footer {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0;
    font-family: Ubbaha-Bold;
    color: #333;
    margin-bottom: 20px;
}
.my-order-item  .card .card-title, .my-order-item .card .card-text, .my-order-item .card .card-footer {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
    border: none;
    padding-left: 10px;
}
.my-order-item .delete-item {
    height: 46px;
    width: 46px;
    margin-left: 40px;
}
.my-order-item .subtotal {
    color: var(--main-color) !important;
}
@media(max-width:991px) {
    .my-order-item .card .card-title, .my-order-item .card .card-text, .my-order-item .card .card-footer, .my-order-item .btn {
        margin-left: 15px;
        margin-bottom: 0;
    }
    .my-order-item .card .card-title {
        margin-bottom: 20px;
    }
}
@media(max-width:767px) {
    .my-order-item .card .card-title {
        font-size: 22px;
        line-height: 33px;  
        margin-bottom: 10px; 
    }
    .my-order-item .card .card-text {
        margin-bottom: 10px !important;
        font-size: 18px;
        line-height: 28px;
    }
    .my-order-item .card {
        border-radius: 12px;
    }
}
@media(max-width:500px) {
    .my-order-item .card .card-title {
        margin-left: 0 !important;
    }
    .my-order-item .card .card-text {
        margin-left: 0 !important;
    }
}
