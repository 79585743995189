.home .image-gallery-content .image-gallery-slide .image-gallery-image {
    object-fit: cover;
    cursor: default;
    min-height: 100vh;
    max-height: 100vh;
}
.home .image-gallery-bullet:hover,
.home .image-gallery-bullet:focus,
.home .image-gallery-bullet.active:hover,
.home .image-gallery-bullet.active:focus {
    border-color: var(--main-color);
    background-color: var(--main-color);
}
.home .image-gallery-left-nav, .home .image-gallery-right-nav {
    transform: translateY(0);
    top: 92%;
    height: auto;
    width: auto;
    padding: 0;
}
.home .image-gallery-right-nav {
    margin-right: 38px;
}
.home .image-gallery-left-nav {
    left: 88%;
}
.home .image-gallery-left-nav .image-gallery-svg, .home .image-gallery-right-nav .image-gallery-svg {
    width: 30px;
    height: 30px;
    background-color: #fff;
    color: var(--main-color);
    font-family: Ubbaha-Bold;
    border-radius: 50%;
}
.image-gallery-icon:hover .image-gallery-svg {
    color: var(--main-color);
    transform: scale(1);
}

.home .image-gallery-svg:focus {
    outline: none;
    border: none;
}

/*media*/ 
@media(max-width:991px) {
    .home .image-gallery-left-nav {
        margin-left: -38px;
    } 
}
@media(max-width:767px) {
    .home .image-gallery-content .image-gallery-slide .image-gallery-image {
        object-fit: contain;
        min-height: auto;
    }
    .home .image-gallery-left-nav, .home .image-gallery-right-nav {
        top: 85%;
        display: none;
    }
    .home .image-gallery-left-nav {
        left: 0;
        margin-left: 38px;
    } 
}
