.loading-page {
  margin-top: -87px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  margin-top: 87px;
}
.loading div {
  position: absolute;
  background: var(--main-color);
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -0.7s;
}
@keyframes loading {
  0% {
    top: 50px;
    left: 50px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 100px;
    height: 100px;
    opacity: 0;
  }
}
