.about {
    padding: 60px;
    min-height: 100vh;
}
.about * {
    padding: 0;
    margin: 0;
}
.about .about-image img {
    width: 100%;
}
.about .about-header {
    font-size: 30px;
    line-height: 46px;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: var(--main-color);
    margin-bottom: 30px;
    text-transform: capitalize;
}
.about .about-description {
    margin-bottom: 20px;
    margin-top: 30px;
}
.about .about-description p {
    margin-bottom: 0;
    color: var(--main-color);
    font-size: 20px;
    font-family: Ubbaha-Bold;
    line-height: 30px;
    letter-spacing: 0;
}
@media(max-width:991px) {
    .about {
        padding: 30px 15px;
    }
    .about .about-description {
        margin-top: 15px;
    }
    .about .about-logo {
        display: none;
    }
    .about .about-header {
        padding-left: 5px;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .about .about-description p {
        font-size: 18px;
    }
}
@media(max-width:767px) {
    .about {
        padding: 30px 15px;
        background-color: #fff;
    } 
    .about .about-image {
        width: 330px;
        height: 330px;
        margin: 0 auto !important;
    }
    .about .about-image img  {
       width: 100%;
       height: 100%;
    }

}