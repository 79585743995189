.footer {
    padding: 30px 60px 0;
    background-color: var(--main-color);
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
}
.footer * {
    padding: 0;
    margin: 0;
}
.footer p, a {
    margin: 0;
    padding: 0;
}
.footer .footer-section-header {
    margin: 0;
    padding: 0;
    font-size: 20px;
    font-family: Ubbaha-Bold;
    line-height: 30px;
    color: #fff;
    text-align: center;
    margin-bottom: 30px;
}
.footer .footer-contact-us p {
    text-align: right;
}
.footer .address-item {
    margin-bottom: 25px;
    display: flex;
    align-items: center;
}
.footer .address-item img {
    margin-right: 15px;
}
.footer-hot-categories-items p, .address-item a {
    font-size: 18px;
    font-family: Ubbaha-Bold;
    line-height: 28px;
    color: #fff;
    text-decoration: none;
}
.footer-hot-categories-items p {
    margin-bottom: 20px;
}
@media(max-width:767px) {
    .footer {
        padding: 15px;
    }
    .footer .footer-section-header {
        text-align: left;
        margin-bottom: 30px;
    }
    .footer .footer-section-header-contact {
        margin-top: 40px;
    }
    .footer-logo {
        margin-bottom: 40px;
        text-align: center;
    }
    .footer .footer-contact-us .row {
       justify-content: center;
       text-align: center;
    }
    .footer .footer-section-header {
        font-size: 18px;
        line-height: 28px;
    }
    .footer .footer-contact-us p {
        text-align: left;
    }
    .footer-hot-categories-items p, .address-item a  {
        font-size: 16px;
    }
}