.product {
    padding: 45px;
}
.product p {
    padding: 0;
}
.product .card {
    background: transparent;
    border: none;   
}
.product .card .card-body {
    padding: 0;
}
.product .card .card-footer {
    background-color: transparent;
    border: none;
    padding: 0;
}
.product .card .card-text, .product td {
    border-top: none;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0;
    font-family: Ubbaha-Bold;
    color: var(--main-color);
    text-align: left;
    margin-right: 10px;
    margin-bottom: 20px;
}
.product .price {
    font-size: 22px;
    font-family: Ubbaha-Bold;
    line-height: 33px;
    letter-spacing: 0;
    color: #333;
}
.product .card .card-row {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
}
.product .card .description {
    margin-bottom: 30px;
    color: #333;
}
.product .card .card-title {
    font-size: 30px;
    font-family: Ubbaha-Bold;
    line-height: 46px;
    letter-spacing: 0;
    color: var(--main-color);
    margin-bottom: 10px;
    text-transform: capitalize;
}
.product .item {
    /* height: 50px; */
    /* background: #FFF; */
    /* box-shadow: 0px 0px 2px #00000080; */
    /* border-radius: 8px; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    font-size: 22px;
    font-family: Ubbaha-Bold;
    line-height: 33px;
    letter-spacing: 0;
    color: #333;
    /* padding: 6px 20px; */
    cursor: pointer;
    margin-right: 10px;
    margin-bottom: 20px;
}
.product .payment .btn {
    margin-top: 30px;
    background-color: var(--main-color);
    height: 46px;
    min-width: 450px;
    box-shadow: 0px 0px 6px #00000080;
    border-radius: 8px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    font-family: Ubbaha-Bold;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
}
.product .payment .btn:focus-visible,
.product .payment .btn:focus,
.product .payment .btn:hover,
.product .payment .btn:active {
   background-color: var(--main-color) !important;
   box-shadow: 0px 0px 6px #00000080 !important;
}
.product .payment .add-cart img {
    margin-right: 10px;
}
.product .quantity .btn {
    height: 40px;
    width: 150px;
    background: #FFF;
    box-shadow: 0px 0px 4px #00000080;
    color: var(--main-color);
    border-radius: 12px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    font-family: Ubbaha-Bold;
    font-size: 20px;
    line-height: 1px;
    letter-spacing: 0;
    padding: 2px;
    margin-bottom: 20px;
}
.product .quantity .btn:focus-visible,
.product .quantity .btn:focus,
.product .quantity .btn:hover,
.product .quantity .btn:active {
   box-shadow: 0px 0px 4px #00000080 !important;
   background-color: #fff !important;
   color: var(--main-color) !important;
}
.product .quantity .icon {
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    height: 100%;
    width: 36px;
    cursor: pointer;
}
.product table tr {
    margin-bottom: 30px !important;
}
.product table td {
    border: none !important;
    margin-right: 10px !important;
}
.product .table-title p {
    transform: translate(0,10%);
}
.product .card .card-img {
    box-shadow: 0px 0px 2px #00000080;
    border-radius: 12px;
    margin-bottom: 30px;
}
@media(max-width:991px) {
    .product {
        padding: 30px 0;
        background-size: cover;
        background-color: #fff;
    }   
    .product .card {
        /* margin-top: 50px; */
        padding: 0 !important;
    }
    .product .card .card-title {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 15px;
    }
    .product .card .card-text, .product td, .product .item, .product .price {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 15px;
        font-family: Ubbaha-Bold;
    }
    .product .payment .btn {
        min-width: auto;
        width: 80%;
        height: 40px;
    }
    .product .price {
        margin-bottom: 0;
    }
    .product .card .description {
        margin-bottom: 30px;
    }
}
@media(max-width:767px) {
    .product .payment .btn {
        min-width: auto;
        width: 100%;
    }
    .product .card .card-row {
        margin-bottom: 10px;
    }
}









