.category-empty-page{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -65px;
    min-height: 100vh;
}
.category-empty-page * {
    margin: 0;
    padding: 0;
}
.category-empty-page .empty-content .empty-img {
    text-align: center;
    margin-bottom: 10px;
}
.category-empty-page .empty-content-description {
    color: var(--main-color);
    font-size: 24px;
    line-height: 37px;
    font-family: Ubbaha-Bold;
    text-align: center;
}
@media(max-width:767px) {
    .category-empty-page {
        background: #fff;
    }
    .category-empty-page .empty-content .empty-img img {
        width: 40px;
        height: 40px;
    }
    .category-empty-page .empty-content-description {
        font-size: 22px;
        line-height: 30px;
    }
}