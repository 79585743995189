.verify {
    margin-top: -65px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.verify form {
    margin-top: 65px;
}
.verify .verify-header h3 {
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
    font-size: 30px;
    font-family: Ubbaha-Bold;
    line-height: 46px;
    letter-spacing: 0px;
    color: var(--main-color);
    text-transform: capitalize;
}
.verify .verify-header p {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    font-size: 15px;
    font-family: Ubbaha-Bold;
    line-height: 21px;
    letter-spacing: 0px;
    color: #333;
}
.verify form .btn {
    background: var(--main-color);
    border: 1px solid var(--main-color);
    color: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
    font-size:18px;
    font-family: Ubbaha-Bold;
    line-height: 28px;
    letter-spacing: 0px;
    color: #fff;
    height: 46px;
}
.verify span {
    cursor: pointer;
}
.verify span p, .verify .append-text {
    text-align: right;
    margin-bottom: 30px;
    font-size: 15px;
    font-family: Ubbaha-Bold;
    line-height: 21px;
    letter-spacing: 0px;
    color: #333;
}
.verify span p {
    text-decoration: underline;
}
.verify form .btn:active, .verify form .btn:hover, .verify form .btn:visited {
    background: var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
}
.verify form .form-control {
    border: 1px solid var(--main-color);
    margin-bottom: 30px;
    font-size: 18px;
    font-family: Ubbaha-Bold;
    line-height: 28px;
    letter-spacing: 0px;
    background: rgba(51, 51, 51, .5);
    border-radius: 8px;
    height: 46px;
    background-color: #FFF !important;
}
@media (max-width:991px) {
    .verify {
        padding: 30px 0;
        background-color: #fff;
    }
    .verify .verify-header h3 {
        margin-bottom: 15px;
        font-size: 30px;
        line-height: 46px;
    }
    .verify span p, .verify .append-text {
        margin-bottom: 25px;
    }
    .verify form .form-control, .verify form .btn {
        font-size: 15px;
        line-height: 20px;
        height: 40px;
    }
}
