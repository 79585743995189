.categories {
    padding: 60px;
    min-height: 100vh;
}
.categories .categories-header {
    font-size: 30px;
    line-height: 46px;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: var(--main-color);
    margin-bottom: 30px;
    text-transform: capitalize;
}
.categories .category-item  {
    height: 267px;
    width: 267px;
    margin: 0 auto;
    border-radius: 24px;
    overflow: hidden;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 1em;
    transition: all .6s ease-in-out;
    box-shadow: 0px 0px 3px #00000035 !important;
}
.categories .category-item:hover {
    box-shadow: 0px 10px 20px var(--main-color) !important;
    transform: translateY(-10px);
}
.categories .category-item-name {
    text-align: center;
    font-size: 22px;
    line-height: 30px;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: var(--main-color);
    margin: 10px 0 30px;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
@media(max-width:991px) {
    .categories {
        padding: 30px 15px;
    }
    .categories .container-fluid {
        padding-left: 10px;
        padding-right: 10px;
    }
    .categories .container-fluid .col-6 {
        padding-right: 5px;
        padding-left: 5px;
    }
    .categories .categories-header {
        padding-left: 5px;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 20px;
    }
    .categories .category-item {
        border-radius: 12px;
        width: 160px;
        height: 160px;
        margin: 0 auto;
    }
    .categories .category-item-name {
        font-size: 18px;
        line-height: 28px;
        margin: 10px 0 15px;
    }
}
@media(max-width:355px) {
    .categories .container-fluid .col-6 {
        padding-right: 0;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .categories .category-item {
        transform: scale(.9);
    }
    .categories .category-item-name {
        margin: 5px 0 10px;
    }
}