.my-orders {
    padding: 60px;
    min-height: 100vh;
}
.my-orders *, .my-orders .my-orders-item * {
    padding: 0;
    margin: 0;
}
.my-orders h3 {
    font-size: 30px;
    line-height: 46px;
    letter-spacing: 0;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: var(--main-color);
    margin-bottom: 30px;
    text-transform: capitalize;
}
.my-orders-item {
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 6px #00000080;
    border-radius: 8px;
    padding: 20px;
}
.my-orders .my-orders-item  .my-order-header {
    margin-bottom: 20px;
}
.my-orders-item .btn {
    background: #fff !important;
    box-shadow: inset 0px 0px 3px #00000059 !important;
    border-radius: 23px;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0;
    font-family: Ubbaha-Bold;
    min-width: 219px;
    height: 46px;
}
.my-orders-item .btn:active, .my-orders-item .btn:hover, .my-orders-item .btn:visited, .my-orders-item .btn:focus {
    cursor: default;
} 
.my-orders-item .installed-btn {
    border: 2px solid #F27F25 !important;
    color: #F27F25 !important;
}
.my-orders-item .delivered-btn {
    border: 2px solid #008D0A !important;
    color: #008D0A !important;
}
.my-orders-item .canceled-btn {
    border: 2px solid #C00000 !important;
    color: #C00000 !important;
}
.my-orders-item .canceled-btn:active, .my-orders-item .canceled-btn:hover, .my-orders-item .canceled-btn:visited, .my-orders-item .canceled-btn:focus {
    cursor: pointer;
}
.my-orders-item .order-method, .my-orders-item .order-on, .my-orders-item .order-total {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: #333;
}
.my-orders-item .order-total {
    min-width: 219px;
    text-align: center;
}
.my-orders-item .order-number {
    font-size: 24px;
    line-height: 37px;
    letter-spacing: 0;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: var(--main-color);
}
.my-orders-item .order-on {
    margin-bottom: 10px;
}
@media(max-width:1199px) {
    .my-orders .my-orders-item {
        width: 100%;
    }
    .my-orders-item .order-total {
        text-align: left;
    }
}
@media(max-width:991px) {
    .my-orders {
        background-color: #fff;
        padding: 30px 15px;
    }
    .my-orders h3 {
        font-size: 22px;
        line-height: 30px;
    }
    .my-orders .order-number {
        font-size: 18px;
        line-height: 30px;
    }
    .my-orders-item .order-method, .my-orders-item .order-on, .my-orders-item .order-total, .my-orders-item .btn {
        font-size: 16px !important;
        line-height: 28px;
    }
}
@media(max-width:767px) {
    .my-orders h3 {
        margin-right: 15px;
    }
}
@media(max-width:576px) {
    .my-orders-item{
        padding: 15px;
    }
    .my-orders-item .btn {
        min-width: 150px;
        font-size: 18px;
        height: 35px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-width: 1px !important;
    }
    .my-orders-item .order-method {
        margin-bottom: 10px;
    }
}