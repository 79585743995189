.not-found {
  padding: 1em;
  font-family: Ubbaha-Bold;
  margin-top: -65px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.not-found main {
  margin-top: 65px;
  flex-direction: column;
  text-align: center;
  width: 100%;
}
.not-found main .lead {
  text-align: center;
  font-size: 30px;
  line-height: 46px;
  letter-spacing: 0px;
  color: var(--main-color);
}
.not-found svg {
  color: var(--main-color);
}
.not-found h1 {
  color: var(--main-color);
  font-size: 10rem;
  letter-spacing: 0.1em;
  margin: 0.1em 0;
  text-shadow: 0.05em 0.05em 0 rgba(0, 0, 0, 0.25);
  white-space: nowrap;
  width: 100%;
}
.not-found h1 > span {
  animation: spooky 2s alternate infinite linear;
  color: var(--main-color);
  display: inline-block;
}
.not-found h2 {
  font-size: 36px;
  line-height: 55px;
  letter-spacing: 0px;
  color: var(--main-color);
}
@keyframes spooky {
  from {
    transform: translatey(0.15em) scaley(0.95);
  }
  to {
    transform: translatey(-0.15em);
  }
}
@media(max-width:991px) {
  .not-found {
    background: #fff;
  }
  .not-found main h2 {
    font-size: 28px;
    line-height: 18px;
  }
}
@media(max-width:767px) {
  .not-found h1 {
    font-size: 9rem;
  }
}