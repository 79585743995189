.hot-categories {
    margin-bottom: 30px;
}
.hot-categories .hot-categories-header {
    width: 100%;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    flex-wrap: wrap;
}
.hot-categories .hot-categories-header h2 {
    padding: 0;
    margin: 0;
    font-size: 30px;
    font-family: Ubbaha-Bold;
    line-height: 46px;
    letter-spacing: 0px;
    color: var(--main-color);
    text-transform: capitalize;
}
.hot-categories .card-text {
    text-align: center;
    font-size: 22px;
    line-height: 30px;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: var(--main-color);
    margin: 10px 0 30px;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.hot-categories .hot-categories-header .see-all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.hot-categories .hot-categories-header .see-all p {
    margin-bottom: 0;
    font-size: 24px;
    font-family: Ubbaha-Bold;
    line-height: 37px;
    letter-spacing: 0px;
    color: var(--main-color);
    margin-right: 5px;
}
.hot-categories .hot-categories-header .see-all-images {
    display: flex;
    justify-content: center;
    align-items: center;
}
.hot-categories .hot-categories-header .see-all-image {
    margin-right: -.9em;
}
.hot-categories .hot-category {
    height: 267px;
    width: 267;
    border-radius: 24px;
    overflow: hidden;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    transition: all .6s ease-in-out;
}
.hot-categories .hot-category:hover {
    box-shadow: 0px 10px 20px var(--main-color);
    transform: translateY(-10px);
}
/*media*/
@media(max-width:991px) {
    .categories {
        padding: 30px 15px;
    }
    .hot-categories .hot-categories-header h2 {
        font-size: 30px;
        line-height: 46px;
    }
    .hot-categories .hot-categories-header .see-all p  {
        font-size: 20px;
        margin-right: 1px;
    }
    .hot-categories .hot-categories-header .see-all-images img {
        width: 16px;
        height: 16px;
    }
    .hot-categories .hot-categories-header .see-all-image {
        margin-right: -.7em;
    }
}
@media(max-width:991px) {
    .hot-categories .container-fluid {
        padding-left: 10px;
        padding-right: 10px;
    }
    .hot-categories .container-fluid .col-6 {
        padding-right: 5px;
        padding-left: 5px;
    }
    .hot-categories .hot-categories-header {
        margin-bottom: 20px;
    }
    .hot-categories .hot-categories-header h2 {
        padding-left: 5px;
        font-size: 22px;
        line-height: 30px;
    }
    .hot-categories .hot-categories-header .see-all p {
        font-size: 18px;
        line-height: 28px;
    }
    .hot-categories .hot-category {
        border-radius: 12px;
        width: 160px;
        height: 160px;
        margin: 0 auto;
    }
    .hot-categories .card-text  {
        font-size: 18px;
        line-height: 28px;
        margin: 10px 0 15px;
    }
}
@media(max-width:355px) {
    .hot-categories .container-fluid .col-6 {
        padding-right: 0;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .hot-categories .hot-category {
        transform: scale(.9);
    }
    .hot-categories .card-text {
        margin: 5px 0 10px;
    }
}