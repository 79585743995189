/*------------------Template------------------

  Theme Name: Ubbaha;
  ============================================

  Theme Coder: Hani Abdullah;
  ============================================

  Created: November 2021;
  ============================================

  Description: The first trademark of the Syrian Crafts Company that includes a group of Syrian crafts - specializing in faience shells, blown glass, crochet candles, al-Atamine, al-Ighbani, and others.
  ============================================

*/

/*------------------{ General Styles }------------------*/
* {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
		   -o-box-sizing: border-box;     
          box-sizing: border-box;
          padding: 0;
          margin: 0;
}
:root {
  --main-color: #7C264B;
  --second-color: #6DDDF8;
  --third-color: #888;
  --bg-color: #f8f6f6;
}

@font-face { font-family: Ubbaha; font-weight: 400; src: url('../assets/fonts/UBBAHA.TTF');}
@font-face { font-family: Ubbaha-Bold; font-weight: bold; src: url('../assets/fonts/UBBAHA-BOLD.TTF');}

body {
  background-color: #fff;

}
/*------------------{ App Style }------------------*/
.app {
  background-image: url(../assets/imgs/bg/bg-1.png);
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 139% -100px;
  min-height: 100vh;
}
.app .content {
  padding-top: 65px;
  min-height: 100vh;
}
@media(max-width:991px) {
  .app {
    background-size: cover;
    background-position: -50% 50%;
  }
}

/*------------------{ Form Style }------------------*/
form .form-control:focus, form .form-control:focus-visible {
  outline: none;
  box-shadow: none;
}
form .form-control:disabled {
  background-color: #f5f5f5 !important;
}

/*------------------{ Button Style }------------------*/
.btn:active, .btn:hover, .btn:visited, .btn:focus,
.swal2-styled.swal2-confirm:active,
.swal2-styled.swal2-confirm:hover,
.swal2-styled.swal2-confirm:visited,
.swal2-styled.swal2-confirm:focus {
  outline: none !important;
  box-shadow: none !important;
}
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  font-family: "Ubbaha-Bold" !important;
  min-height: 166px;
}
.swal2-html-container, .swal2-container {
  padding: 0;
  margin: 0;
}
.swal2-html-container {
  font-family: "Ubbaha-Bold" !important;
  font-size: 20px !important;
  line-height: 30px;
  letter-spacing: 0;
  margin-bottom: 30px;
  display: flex !important;
  align-items: center;
}
.swal2-styled.swal2-confirm, .swal2-styled.swal2-deny {
  background-color: var(--main-color) !important;
  color: #fff;
  border: 1px solid var(--main-color) !important;
  box-shadow: inset 0px 0px 8px #00000059 !important;
  border-radius: 8px !important;
  font-size: 18px !important;
  font-family: Ubbaha-Bold !important;
  line-height: 28px !important;
  letter-spacing: 0 !important;
  height: 46px !important;
  min-width: 150px !important;
  padding: 0 !important;
}
.swal2-styled.swal2-confirm:active,
.swal2-styled.swal2-confirm:hover,
.swal2-styled.swal2-confirm:visited {
  background-color: var(--main-color) !important;
  color: #fff !important;
}
.swal2-styled.swal2-deny,
.swal2-styled.swal2-deny:active,
.swal2-styled.swal2-deny:hover,
.swal2-styled.swal2-deny:visited {
  background-color: #fff !important;
  color: var(--main-color) !important;
}
.swal2-icon {
  display: none !important;
}
/*------------------{ Scroll Style }------------------*/
.scroll {
  cursor: pointer;
  position: fixed;
  right: 20px;
  bottom: 10px;
}
@media(max-width:767px) {
  .scroll img {
    width: 30px;
    height: 30px;
  }
}