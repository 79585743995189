.checkout-cart {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 144px;
    box-shadow: 0px -6px 6px #00000059;
    background-color: #FFF;
    font-family: Ubbaha-Bold;
    font-size: 24px;
    line-height: 37px;
    letter-spacing: 0; 
    display: flex;
    align-items: center;
    color: #333;
}
.checkout-cart table {
    width: 498px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.checkout-cart table tr td {
    border: none;
    padding: 0;
    margin: 0;
}
.checkout-cart table tr td:first-of-type {
    text-align: left;
}
.checkout-cart table tr td:last-of-type {
    text-align: right;
}
.checkout-cart .btn {
    padding: 0;
    background-color: var(--main-color);
    border-radius: 8px;
    min-width: 498px;
    height: 46px;
    margin: 0 auto;
    border: var(--main-color);
    font-family: Ubbaha-Bold;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0;
    display: flex;
    justify-content: center;
    align-items: center;
} 
.checkout-cart .btn:active,
.checkout-cart .btn:visited,
.checkout-cart .btn:focus,
.checkout-cart .btn:focus-visible,
.checkout-cart .btn:hover {
    background-color: var(--main-color) !important;
    border: var(--main-color) !important;
    color: #fff !important;
}
@media(max-width:767px) {
    .checkout-cart {
        font-size: 20px;
        line-height: 30px;
        height: 120px;
    }
    .checkout-cart table {
        min-width: auto;
        width: 100%;
        margin-bottom: 10px;
    }
    .checkout-cart .btn {
        min-width: auto;
        width: 80%;
        margin: 0 auto;
        font-size: 18px;
        line-height: 28px;
        height: 40px;
    }
}