.profile {
    padding: 60px 0;
}
.profile form {
    background: #FFF;
    box-shadow: 0px 0px 6px #00000080;
    border-radius: 8px;
    opacity: 1;
    padding: 30px;
    margin-bottom: 40px;
}
.profile form h3 {
    padding: 0;
    margin: 0;
    font-family: Ubbaha-Bold;
    font-size: 30px;
    letter-spacing: 0px;
    line-height: 46px;
    color: var(--main-color);
    margin-bottom: 30px;
    text-transform: capitalize;
}
.profile form .btn,
.profile form .btn:active,
.profile form .btn:hover,
.profile form .btn:visited {
    background: var(--main-color) !important;
    color: #fff;
    border: 1px solid var(--main-color);
    box-shadow: inset 0px 0px 8px #00000059 !important;
    border-radius: 8px;
    font-size: 18px;
    font-family: Ubbaha-Bold;
    line-height: 28px;
    letter-spacing: 0;
    min-width: calc(50% - 10px);
    margin-left: 20px;
    box-shadow: none;
    height: 46px;
}
.profile form .btn-save-changes,
.profile form .btn-save-changes:active,
.profile form .btn-save-changes:hover,
.profile form .btn-save-changes:visited {
    background: #fff !important;
    color: var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
    margin-left: 0;
}
.profile .form-button-password .btn:active,
.profile .form-button-password .btn:hover,
.profile .form-button-password .btn:visited {
    color: #fff !important;
    border: 1px solid var(--main-color) !important;
}
.profile form .form-label, .profile form .form-control {
    font-family: Ubbaha-Bold;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 28px;
    color: #333;
    flex-basis: 20%;
}
.profile form div:not(.profile form div:last-of-type) {
    margin-bottom: 30px;
}
.profile form .form-control {
    border: 1px solid var(--main-color);
    border-radius: 8px;
    flex-basis: 80%;
    height: 46px;
}
.profile form .form-control:disabled {
    background-color: #f5f5f5;
}
.profile form:last-of-type .form-label {
    flex-basis: 32%;
}
.profile form:last-of-type .form-control {
    flex-basis: 68%;
}
.profile form:last-of-type .form-button {
    width: 71%;
    margin-left: 29%;
    transform: translate(25%);
}
.profile form .form-control:focus, .profile form .form-control:focus-visible {
    outline: none;
    box-shadow: none;
}
.profile form .first-name {
    margin-bottom: 20px;
}
.profile form .first-name, .profile form .last-name {
    position: relative;
}
.profile form .first-name img, .profile form .last-name img,.profile form .password-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-10px, -50%);
    cursor: pointer;
    color: var(--main-color);
}
.profile .profile-img img {
    width: 188px;
    height: 188px;
    border-radius: 50%;
    background: transparent;
    box-shadow: 0px 0px 8px #00000080;
    opacity: 1;
}
.profile .form-button {
    margin-left: 20%;
    width: 80%;
}
.profile form .profile-password {
    position: relative;
}
@media(max-width:991px) {
    .profile {
        padding: 15px 0 0;
        background-color: #fff;
    }
    .profile form {
        padding: 15px 10px;
        box-shadow: 0px 0px 2px #00000080;
        margin-bottom: 15px;
    }
    .profile form h3 {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 15px;
    }
    .profile .profile-img {
        text-align: center;
    }
    .profile .profile-img img {
        width: 150px;
        height: 150px;
    }
    .profile form .form-button {
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
        flex-wrap: wrap;
    }
    .profile form .form-button .btn {
        margin: 0px;
        box-shadow: inset 0px 0px 3px #00000059 !important;
    }
    .profile form div:not(.profile form div:last-of-type) {
        margin-bottom: 15px;
    }
    .profile .form-control, .profile .btn {
        font-size: 15px !important;
        line-height: 20px !important;
        height: 40px !important;
    }
    .profile form .form-label {
        font-size: 17px !important;
        line-height: 20px !important;
    }
    .profile form .first-name img, .profile form .last-name img,.profile form .password-icon {
        top: 82%;
        right: 10px;
        transform: translate(0, -82%);
    }
  
}