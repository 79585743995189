.login {
    margin-top: -65px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login form {
    margin-top: 65px;
}
.login .login-header h3, .login .login-header p {
    padding: 0;
    margin: 0;
    letter-spacing: 0px;
    font-family: Ubbaha-Bold;
}
.login .login-header h3 {
    margin-bottom: 30px;
    font-size: 30px;
    line-height: 46px;
    color: var(--main-color);
}
.login .login-header p {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 21px;
    color: #333;
}
.login form .btn {
    background: var(--main-color);
    border: 1px solid var(--main-color);
    color: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
    font-size: 18px;
    font-family: Ubbaha-Bold;
    line-height: 28px;
    letter-spacing: 0px;
    color: #fff;
    height: 46px;
}
.login span {
    cursor: pointer;
}
.login span p, .login .append-text {
    text-align: right;
    margin-bottom: 30px;
    font-size: 15px;
    font-family: Ubbaha-Bold;
    line-height: 21px;
    letter-spacing: 0px;
    color: #333;
}
.login span p {
    text-decoration: underline;
}
.login form .btn:active, .login form .btn:hover, .login form .btn:visited {
    background: var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
}
.login .login-mobile, .login .login-password {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.login form .form-control {
    border: 1px solid var(--main-color);
    font-size: 18px;
    line-height: 28px;
    background: rgba(51, 51, 51, .5);
    letter-spacing: 0px;
    font-family: Ubbaha-Bold;
    border-radius: 8px;
    height: 46px;
    background-color: #FFF !important;
}
.login form .login-icon {
    margin-right: 5px;
}
.login form .login-password {
    position: relative;
}
.login form .password-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-10px,-50%);
    color: var(--main-color);
    cursor: pointer;
}
.login form img {
    margin-right: 10px;   
}
.login .create-account, .login .form-button {
    margin-left: 46px;
}
@media (max-width:991px) {
    .login {
        padding: 30px 0;
        background-color: #fff;
    }
    .login .login-header h3 {
        margin-bottom: 15px;
        font-size: 30px;
        line-height: 46px;
    }
    .login span p, .login .append-text {
        margin-bottom: 25px;
    }
    .login img {
        width: 30px;
        height: 30px;
    }
    .login form .form-control, .login form .btn {
        font-size: 15px;
        line-height: 20px;
        height: 40px;
    }
}
@media(max-width:767px) {
    .login .form-button,  .login .create-account {
        margin-left: 0;
    }
}