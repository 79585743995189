.navbar {
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  background: transparent linear-gradient(180deg, #7C264B 0%, #7C264B 100%, #FFF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000059;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  padding: 0 60px;
}
.navbar * {
  padding: 0;
  margin: 0;
}
.navbar-brand img {
  margin: 5px 0;
}
.nav-right {
  margin-right: 40px;
}
.navbar-light .navbar-toggler {
  border: none;
  outline: none;
}
.navbar-light .navbar-toggler:focus-visible,
.navbar-light .navbar-toggler:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.navbar-light .navbar-nav .nav-link, .dropdown-menu a {
  color: #FFF;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0;
  font-family: Ubbaha-Bold;
  padding: 0 20px;
  transition: all .7s ease-in-out;
}
.cart-link {
  padding-right: 0 !important;
}
.navbar-light .navbar-nav .link-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.navbar-light .navbar-nav .link-image img {
  border-radius: 50%;
  margin-right: 5px;
}
.navbar-light .navbar-nav .link-image .counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px; 
  height: 20px;
  position: absolute;
  top: 63%;
  right: 40%;
  background: #fff;
  color: var(--main-color);
  border-radius: 50%;
  font-size: 9.5px;
}
.navbar-light .navbar-nav .nav-link:not(.nav-icon .nav-link):hover,
.navbar-light .navbar-nav .nav-link:not(.nav-icon .nav-link).active {
  background: #fff;
  opacity: 0.8;
  color: var(--main-color);
}
.nav-icon .nav-link:hover, .nav-icon .nav-link:active, .dropdown a:first-child:focus, .dropdown a:first-child:hover, .dropdown a:first-child:active{
  color: #fff !important;
}
.dropdown-menu {
  top: 40px;
  left: -50%;
  min-width: 160px;
  box-shadow: 0px 0px 6px #00000080;
  overflow: hidden;
  border-radius: 9px;
  overflow: hidden;
  padding: 0;
}
.dropdown-menu a {
  font-size: 18px;
  font-family: Ubbaha;
  text-align: left;
  letter-spacing: 0px;
  color: #333;
  background-color: #fff;
  padding: 10px;
  transition: all .3s ease-out;
  display: flex;
  align-items: center;
}
.dropdown-menu a img:last-of-type {
  display: none;
}
.dropdown-menu a:hover {
  background-color: rgba(125, 38, 75,.7);
  color: #fff !important;
}
.dropdown-menu a:hover img:first-of-type {
  display: none;
}
.dropdown-menu a:hover img:last-of-type {
  display: block;
}
.dropdown-menu .icon-dropdown {
  margin-right: 5px;
}
.dropdown a:first-child:focus-visible {
  border: none;
  outline: none;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
} 
.dropdown-toggle::after {
  display: none!important;
}
/* responsive */
@media (min-width:992px) {
  a#UserDropdown {
    padding: 0;
  }
  .nav-right {
    align-items: center;
  }
  .navbar-light .navbar-nav .nav-link:not(.nav-icon .nav-link):hover,
  .navbar-light .navbar-nav .nav-link:not(.nav-icon .nav-link).active {
    padding: 18.1px;
  }
}
@media (max-width: 991px) {
  .navbar {
    padding: 0 15px;
  }
  .navbar-collapse {
    background-color: #fff;
    margin: 1em 0;
    border-radius: .3em !important;
    overflow: hidden;
    padding: 1em;
  }
  .navbar-light .navbar-nav .nav-link {
    color: var(--main-color);
    background-color: #fff;
    margin-bottom: .8em;
    text-align: center;
    transition: all 1.2s ease-in-out;
    border: 1px solid var(--main-color);
    padding: 5px;
    border-radius: .2em;
  }
  .navbar-light .navbar-nav .nav-link:last-of-type {
      margin-bottom: 0;
  }
  .navbar-light .navbar-nav .nav-link:hover {
    background-color: rgba(125, 38, 75,.7) !important;
    color: #fff !important;
    padding-left: 1em;
  }
  .navbar-light .navbar-nav .link-image img:first-of-type,
  .navbar-light .navbar-nav .link-image img:last-of-type
   {
    display: none;
  }
  .navbar-nav {
    margin: 1em auto !important;
  }
  .dropdown {
    width: 100%;
    margin-bottom: 1em;
  }
  .dropdown a {
    color: var(--main-color) !important;
    transition: all 1.2s ease-in-out;
    padding: 5px 10px;
  }

  .dropdown a:hover {
    color: #fff !important;
    padding-left: 1em;

  }
  .dropdown-menu {
    margin-top: .2em;
    box-shadow: none;
    top: 0;
    border: 1px solid var(--main-color);
    border-radius: .2em;
  }
  a#UserDropdown:active {
    color: var(--main-color) !important;
  }
  .navbar-light .navbar-nav .link-image .counter {
    display: none;
  }
}
