.home-navbar {
  position: fixed;
  z-index: 9999999999;                                                                                        
  width: 100%;
  background: transparent linear-gradient(180deg, #7C264B 0%, #7C264BB4 48%, #7C264B00 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000059;
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
}
.nav_scroll {
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  background: transparent linear-gradient(180deg, #7C264B 0%, #7C264B 100%, #FFF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000059;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}