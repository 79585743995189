.arrivals .arrivals-header h2 {
    padding: 0;
    margin: 0;
    text-align: left;
    font-size: 30px;
    line-height: 46px;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: var(--main-color);
    margin-bottom: 40px;
    text-transform: capitalize;
}
.arrivals .arrival {
    background: #FFF;
    box-shadow: 0px 4px 7px #00000059;
    border: none;
    border-radius: 24px;
    margin-bottom: 40px;
    overflow: hidden;
}
.arrivals .arrival .card-img { 
    border-radius: 24px 24px 0 0;
    cursor: pointer;
    overflow: hidden;
    min-height: 190px ;
    max-height: 190px;
}
.arrivals .arrival img {
    width: 100%;
    height: 100%;
}
.arrivals .arrival .card-body {
    padding: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.arrivals .card-body .card-text {
    overflow: hidden;
}
.arrivals .arrival-name {
    font-size: 24px;
    font-family: Ubbaha-Bold;
    line-height: 37px;
    letter-spacing: 0px;
    color: #333;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.arrivals .arrival-description {
    font-size: 20px;
    font-family: Ubbaha;
    line-height: 30px;
    letter-spacing: 0px;
    color: #333;
    margin-bottom: 25px;
}
.arrivals .arrival-price {
    font-size: 24px;
    font-family: Ubbaha-Bold;
    line-height: 37px;
    letter-spacing: 0px;
    color: var(--main-color);
    text-align: right;
}
@media(max-width:991px) {
    .arrivals {
        margin-top: 30px;
    }
    .arrivals .container-fluid {
        padding-left: 10px;
        padding-right: 10px;
    }
    .arrivals .container-fluid .col-6 {
        padding-right: 5px;
        padding-left: 5px;
    }
    .arrivals .arrival {
        border-radius: 12px;
        margin: 0 0 15px;
        box-shadow: 0px 2px 3px #00000059;
    }
    .arrivals .arrival .card-img {
        border-radius: 12px 12px 0 0;
    }
    .arrivals .arrivals-header h2 {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 20px;
        margin-left: 5px;
    }
    .arrivals .arrival-name {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 2px;
    }
    .arrivals .arrival-price,.arrivals .arrival-description {
        font-size: 18px;
        line-height: 28px;
    }
    .arrival-description {
        margin-bottom: 15px !important;
    }
    .arrivals .arrival .card-img {
        min-height: 125px;
        max-height: 125px;
    }
}
@media(max-width:355px) {
    .arrivals .container-fluid .col-6 {
        padding-right: 0;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .arrivals .arrival {
        transform: scale(.9);
    }
}