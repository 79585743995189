.cart {
    min-height:  calc(100vh - 65px);
    position: relative;
    padding-bottom: 100px;
}
.cart .cart-header {
    font-size: 30px;
    line-height: 46px;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: var(--main-color);
    margin-bottom: 30px;
    text-transform: capitalize;
}
.cart .container-fluid {
    padding: 60px;
}
.cart .container-fluid * {
    padding: 0;
}
.cart .container-fluid .row {
    width: 100%;
}
@media(max-width:991px) {
    .cart {
        background: #fff;
    }
    .cart .cart-header {
        padding-left: 5px;
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 20px;
    }
}
@media(max-width:767px) {
    .cart .container-fluid {
        padding: 30px;
    }
    .cart .container-fluid .row {
        width: auto;
    }
}