.cart-item .card {
    background: #FFF;
    box-shadow: 0px 0px 6px #00000080;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
}
.cart-item  .card * {
    padding: 0;
}
.cart-item  table tr {
    padding: 0 20px !important;
}
.cart-item  table tr td {
    border: none;
}
.cart-item  table tr td span {
    font-family: Ubbaha-Bold;
}
.cart-item .card .card-title {
    font-size: 24px;
    line-height: 37px;
    letter-spacing: 0;
    font-family: Ubbaha-Bold;
    color: #333;
    margin-bottom: 30px;
    margin-top: 20px;
    margin-left: 20px;
}
.cart-item .card .card-text, .cart-item .card .card-footer {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0;
    font-family: Ubbaha-Bold;
    color: #333;
    margin: 0;
}
.cart-item  .card .card-title, .cart-item .card .card-text, .cart-item .card .card-footer {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
    border: none;
}
.cart-item .btn {
    margin-left: 60px;
    height: 50px;
    width: 165px;
    background: #FFF !important;
    box-shadow: 0px 0px 6px #00000080 !important;
    color: var(--main-color) !important;
    border-radius: 12px;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    font-family: Ubbaha-Bold;
    font-size: 24px;
    line-height: 1px;
    letter-spacing: 0;
    padding: 2px;
}
.cart-item .delete-item {
    height: 46px;
    width: 46px;
    margin-left: 40px;
}
.cart-item  .icon {
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
    height: 100%;
    width: 46px;
    cursor: pointer;
}
@media(max-width:991px) {
    
    .cart-item .button-tr {
        margin: 0 25%
    }
    .cart-item  table tr p {
        margin-bottom: 20px !important;
    }
    .cart-item .btn {
        margin-left: 0;
    }
    .cart-item .card .card-title, .cart-item .card .card-text, .cart-item .card .card-footer, .cart-item .btn {
        margin-left: 15px;
    }
    .cart-item .card .card-title {
        padding-left: 15px;
    }
    .cart-item .icon, .cart-item .delete-item  {
        width: 36px;
        height: 100%;
        border-radius: 4px;
    }
    .cart-item .btn {
        height: 40px;
        border-radius: 5px;
        font-size: 22px;
    }
    .cart-item .delete-item {
        height: 38px;
    }
}
@media(max-width:767px) {
    .cart-item .button-tr {
        margin: 0 25%
    }
    .cart-item  table tr p {
        margin-bottom: 20px !important;
    }
    .cart-item .btn {
        box-shadow: 0px 0px 3px #00000080 !important;
        width: 120px;
        height: 30px;
    }
    .cart-item .card .card-title {
        font-size: 22px;
        line-height: 33px;  
        margin-bottom: 10px; 
    }
    .cart-item .card .card-text {
        margin-bottom: 10px !important;
        font-size: 18px;
        line-height: 28px;
    }
    .cart-item .card .card-item-subtotal {
        margin-bottom: 15px !important;
    }
    .cart-item .icon, .cart-item .delete-item  {
        width: 26px;
        height: 100%;
        border-radius: 4px;
    }
    .cart-item .btn {
        height: 30px;
        border-radius: 5px;
        font-size: 19px;
    }
    .cart-item .delete-item {
        height: 28px;
        margin-left: 15px;
    }
    .cart-item .delete-item img {
        width: 20px;
        height: 20px;
    }
    .cart-item .card {
        border-radius: 12px;
    }
}
@media(min-width:598px) and (max-width:991px) {
    .cart-item .card .card-item-subtotal {
        margin-bottom: 0 !important;
    }
}
@media(max-width:500px) {
    .cart-item .card .card-title {
        margin-left: 0 !important;
    }
    .cart-item .card .card-text {
        margin-left: 0 !important;
    }
    .cart-item .btn-delete {
        margin-left: -15px !important;
    }
}
