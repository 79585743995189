.order-details {
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    padding-bottom: 30px;
    min-height: 100vh;
}
.my-order-details-header .container-fluid{
    padding: 0;
}
.order-details p {
    margin: 0;
    padding: 0;
}
.my-order-details {
    padding: 0 60px;
}
.my-order-details * {
    padding: 0;
}
.my-order-details-header {
    background: rgba(51, 51, 51, .5);
    padding: 20px 60px;
    color: #fff;
    font-family: Ubbaha-Bold;
    margin-bottom: 20px;

}
.my-order-details-header .grand-total .cancel-order {
    min-width: 356px;
    height: 46px;
    background: var(--main-color);
    border: 1px solid var(--main-color);
    border-radius: 8px;
    font-size: 18px;
    line-height: 28px;
    box-shadow: inset 0px 0px 8px #00000059 !important;
}
.my-order-details-header .grand-total p {
    font-size: 24px;
    line-height: 37px;
    margin: 0 0 20px;
    padding: 0;
}
.my-order-details-header .cancel-order:hover,
.my-order-details-header .cancel-order:active,
.my-order-details-header .cancel-order:visited,
.my-order-details-header .cancel-order:focus,
.my-order-details-header .cancel-order:focus-visible {
    background: var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
    box-shadow: inset 0px 0px 8px #00000059 !important;
}
.order-details .my-orders-item-details {
    background-color: transparent;
    margin-bottom: 20px;
}
.my-order-item-details-header {
    margin-bottom: 20px;
}
.order-details .my-orders-item-details .order-details-number {
    font-size: 30px;
    line-height: 46px;
    margin-right: 60px;
}
.order-details .my-orders-item-details .order-details-on,
.order-details .my-orders-item-details .order-details-method,
.order-details .my-orders-item-details .order-details-address {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
    margin-bottom: 20px;
}
.order-details .my-orders-item-details .btn {
    background: transparent;
    border-radius: 23px;
    font-size: 20px;
    line-height: 30px;
    min-width: 219px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.order-details .my-orders-item-details .btn:active,
.order-details .my-orders-item-details .btn:hover,
.order-details .my-orders-item-details .btn:visited,
.order-details .my-orders-item-details .btn:focus {
    cursor: default;
    background-color: transparent !important;
} 
.order-details .my-orders-item-details .order-details-installed-btn {
    border: 2px solid #F27F25 !important;
}
.order-details .my-orders-item-details .order-details-delivered-btn {
    border: 2px solid #008D0A !important;
}
.order-details .my-orders-item-details .order-details-canceled-btn {
    border: 2px solid #C00000 !important;
}
@media(min-width:992px) and (max-width:1042px) {
    .order-details-status .btn {
        height: 40px !important;
        min-width: 210px !important;
    }
}
@media(max-width:991px) {
    .my-order-details {
        padding: 30px 15px;
        background-color: #fff;
    }
    .my-order-details-header {
        padding: 30px 15px;
    }
    .order-details .my-orders-item-details .order-details-number {
        font-size: 22px !important;
        line-height: 30px !important;
    }
    .my-order-details-header .grand-total p {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 10px;
    }
    .order-details-status .btn {
        height: 40px !important;
        min-width: auto !important;
        width: 100% !important;
        font-size: 18px !important;
        line-height: 28px;
    }
}
@media(max-width:767px) {
    .my-order-details, .my-order-details-header{
        padding: 20px 15px;
    }
    .order-details .my-orders-item-details .order-details-number {
        font-size: 30px;
    }
    .my-order-details-header .grand-total .btn {
        height: 40px !important;
        min-width: auto !important;
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px !important;
        line-height: 28px;
    }
    .my-order-details-header .grand-total p {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 10px;
    }
}
@media(max-width:345px) {
    .order-details-status .btn {
        font-size: 14.54px !important;
        line-height: 26px;
        width: 50px;
        height: 33px !important;
    }
}
