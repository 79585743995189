.contact {
    padding: 60px;
}
.contact .social p, .contact .message p {
    margin-bottom: 20px;
    color: var(--main-color);
    font-size: 22px;
    font-family: Ubbaha-Bold;
    line-height: 33px;
    letter-spacing: 0px;
}
.contact .contact-header {
    padding: 0;
    margin: 0;
    text-align: left;
    font-size: 30px !important;
    line-height: 46px !important;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: var(--main-color);
    margin-bottom: 40px;
    text-transform: capitalize;
}
.contact .social-map .map {
    width:100%;
    height: 400px;
    overflow:hidden;
    user-select: none;
    margin-bottom: 60px;
}
.contact .social-map .map iframe {
    width:100%;
    height: 100%;
    border: 0;
}
.contact .social-map .social {
    text-align: center;
}
.contact .social .icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.contact .social .icons img {
    margin: 0 20px;
    cursor: pointer;
}
.contact form .form-control {
    border: 1px solid var(--main-color);
    border-radius: 8px;
    height: 40px;
    margin-bottom: 10px;
    background: rgba(51, 51, 51, .5);
    font-family: Ubbaha;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
    padding: 10px;
    background-color: #FFF !important;
}
.contact form textarea {
    height: 80px !important;
}
.contact .address {
    margin: 40px 0;
}
.contact .address .address-item {
    margin: 30px 0;
}
.contact .address .address-item img {
    margin-right: 15px;
}
.contact .address .address-item a {
    color: var(--main-color);
    font-family: Ubbaha;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
    text-decoration: none;
}
.contact .message p {
    margin-bottom: 10px;
    text-transform: capitalize;
}
.contact .message form button {
    margin: 20px auto 0;
    width: 127px;
    height: 40px;
    background: #FFF !important;
    box-shadow: inset 0px 0px 8px #00000059 !important;
    border: 1px solid var(--main-color) !important;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-color) !important;
    font-size: 18px;
    font-family: Ubbaha-Bold;
    line-height: 28px;
    letter-spacing: 0px;
}
.contact .message form button img {
    margin-right: 10px;
}
.contact #follow-sm {
    display: none;
}
.contact .contact-header {
    font-size: 36px;
    line-height: 55px;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: var(--main-color);
    margin-bottom: 30px;
}
@media(max-width:1199px) {
    .contact .message form button {
        margin-bottom: 40px;
    }
    .contact .address {
        transform: translate(0);
    }
}
@media(max-width:991px) {
    .contact {
        padding: 30px 0;
        background: #fff;
    }
    .contact .contact-header {
        padding-left: 15px;
        font-size: 22px !important;
        line-height: 30px !important;
        margin-bottom: 20px;
    }
}
@media(max-width:767px) {
    .contact .contact-header {
        margin-bottom: 0;
    }
    .contact .address .address-item {
        margin: 20px 0;
    }
    .contact .address .address-item a {
        font-size: 18px;
    }
    .contact img {
        width: 25px;
        height: 25px;
    }
    .contact form .form-control, .contact form .btn {
        font-size: 16px !important;
        line-height: 20px;
        height: 40px;
    }
    .contact .message form button {
        box-shadow: inset 0px 0px 3px #00000059 !important;
    }
    .contact #follow {
        display: none;
    }
    .contact #follow-sm {
        display: block;
        text-align: center;
        margin-bottom: 30px;
        background-color: #fff;
        padding: 5px 0;
        border-radius: 8px;
        border: 1px solid var(--main-color);
    } 
    .contact .social-map .map {
        margin-bottom: 15px;
    }
}