.register {
    margin-top: -65px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 75px 0 0;
}
.register form {
    margin-top: 65px;
}
.register .register-header h3 {
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
    font-size: 30px;
    font-family: Ubbaha-Bold;
    line-height: 46px;
    letter-spacing: 0px;
    color: var(--main-color);
    text-transform: capitalize;
}
.register .register-header p {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    font-size: 15px;
    font-family: Ubbaha-Bold;
    line-height: 21px;
    letter-spacing: 0px;
    color: #333;
}
.register form .btn {
    background: var(--main-color);
    border: 1px solid var(--main-color);
    color: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
    font-size:18px;
    font-family: Ubbaha-Bold;
    line-height: 28px;
    letter-spacing: 0px;
    color: #fff;
    height: 46px;
}
.register span {
    cursor: pointer;
}
.register span p, .register .append-text {
    text-align: right;
    margin-bottom: 30px;
    font-size: 15px;
    font-family: Ubbaha-Bold;
    line-height: 21px;
    letter-spacing: 0px;
    color: #333;
}
.register span p {
    text-decoration: underline;
}
.register form .btn:active, .register form .btn:hover, .register form .btn:visited {
    background: var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
}
.register form .form-control {
    border: 1px solid var(--main-color);
    margin-bottom: 20px;
    font-size: 18px;
    font-family: Ubbaha-Bold;
    line-height: 28px;
    letter-spacing: 0px;
    background: rgba(51, 51, 51, .5);
    border-radius: 8px;
    height: 46px;
    background-color: #FFF !important;
}
.register form .register-icon {
    margin-right: 5px;
}
.register .register-password-main {
    margin-top: 40px;
    margin-bottom: 30px;
}
.register form .register-password {
    position: relative;
}
.register form .password-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-10px,-50%);
    color: var(--main-color);
    cursor: pointer;
}
@media (max-width:991px) {
    .register {
        padding: 30px 0;
        background-color: #fff;
    }
    .register .register-header h3 {
        margin-bottom: 15px;
        font-size: 30px;
        line-height: 46px;
    }
    .register span p, .register .append-text {
        margin-bottom: 25px;
    }
    .register img {
        width: 30px;
        height: 30px;
    }
    .register form .form-control, .register form .btn {
        font-size: 15px;
        line-height: 20px;
        height: 40px;
    }
}
@media(max-width:767px) {
    .register .form-button,  .register .create-account {
        margin-left: 0;
    }
}