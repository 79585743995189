.modal {
    padding-top: 65px;
    background: rgb(0, 0, 0, 0);
    opacity: 1;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
}
.modal-content {
    border-radius: 20px;
    margin-top: -1.6px;
}
.modal form *:not(.form-control) {
    padding: 0;
    margin: 0;
}
.modal form {
    padding: 20px;
}
.modal #modal-title {
    text-align: left;
    font-size: 30px;
    line-height: 46px;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: var(--main-color);
    margin-bottom: 30px;
}
.modal #modal-paragraph-total, .modal #modal-paragraph-method, .modal .form-label {
    text-align: left;
    font-size: 20px;
    line-height: 37px;
    font-family: Ubbaha-Bold;
    letter-spacing: 0px;
    color: #333;
    margin-bottom: 30px;
}
.modal #modal-paragraph-method {
    margin-bottom: 20px;
}
.modal-choice-method {
    margin-bottom: 40px !important;
}
.modal .modal-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.modal .btn {
    border: 1px solid var(--main-color) !important;
    min-width: 180px;
    border-radius: 8px;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0;
    font-family: Ubbaha-Bold;
    height: 46px;
}
.modal .modal-btn .modal-btn-submit {
    background: #fff !important;
    color: var(--main-color) !important;
    box-shadow: inset 0px 0px 8px #00000059 !important;
    margin-right: 10px;
}
.modal .modal-btn .modal-btn-cancel {
    background: var(--main-color) !important;
    color: #fff !important;
}
.modal form .form-control:not([type="radio"]) {
    border: 1px solid var(--main-color);
    font-size: 18px;
    font-family: Ubbaha-Bold;
    line-height: 30px;
    letter-spacing: 0px;
    color: #333;
    opacity: .5;
    border-radius: 8px;
    height: 46px;
}
.modal form #delivery, .modal form #backup {
    margin-right: 10px;
}
.modal form #backup-label {
    margin-right: 40px;
}
input[type="radio"] {
    display: none;
  }
input[type="radio"] + label:before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 3px;
    margin-right: 5px;
    background-clip: content-box;
    border: 1px solid #bbbbbb;
    background-color: #fff;
    border-radius: 50%;
}
input[type="radio"]:checked + label:before {
    background-color: var(--main-color);
}
.modal .address-checkout, .modal .mobile-checkout {
    margin-bottom: 10px !important;
    display: flex;
    align-items: center;
}
.modal .address-checkout label, .modal .mobile-checkout label {
    flex-basis: 15%;
}
.modal .address-checkout {
    margin-bottom: 40px !important;
}
@media(max-width:991px) {
    .modal .address-checkout, .modal .mobile-checkout {
        flex-wrap: wrap;
    }
    .modal form {
        padding: 15px;
    }
    .modal #modal-title {
        font-size: 22px;
        line-height: 30px;
    }
    .modal #modal-paragraph-total, .modal #modal-paragraph-method, .modal .form-label {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px;
    }
    .modal .btn,.modal .form-control:not([type="radio"]) {
        font-size: 17px !important;
        height: 40px !important;
    }
    .modal .address-checkout, .modal .mobile-checkout  {
        margin-bottom: 20px !important;
    }
    .modal-choice-method {
        margin-bottom: 20px !important;
    } 
    input[type="radio"] + label:before {
    
        width: 14px;
        height: 14px;
        padding: 2px;
        margin-right: 2px;
    }
}
@media(max-width:767px) {
    .modal .btn {
        min-width: 48.3%;
    }
    .modal .mobile-checkout {
        margin-bottom: 20px !important;
    }
    .modal .address-checkout label, .modal .mobile-checkout label {
        margin-bottom: 5px;
    }
    .modal .modal-btn .modal-btn-submit {
        box-shadow: inset 0px 0px 3px #00000059 !important;
    }
}