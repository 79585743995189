.empty-page {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -65px;
    min-height: 100vh;
}
.empty-page * {
    margin: 0;
    padding: 0;
}
.empty-page .empty-content {
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 0;
   
}
.empty-page .empty-content img {
    margin-bottom: 20px;
}
.empty-page .empty-content-description {
    color: var(--main-color);
    font-size: 24px;
    line-height: 37px;
    font-family: Ubbaha-Bold;
    text-align: center;
}
.empty-page .empty-btn {
    text-align: center;
}
.empty-page .empty-btn .btn {
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    font-family: Ubbaha-Bold;
    text-align: center;
    height: 46px;
    min-width: 180px;
    background-color: var(--main-color);
    border: 1px solid var(--main-color);
    box-shadow: inset 0px 0px 8px #00000059;
    border-radius: 8px;
}
.empty-page .empty-btn .btn:hover,
.empty-page .empty-btn .btn:visited,
.empty-page .empty-btn .btn:focus,
.empty-page .empty-btn .btn:focus-visible,
.empty-page .empty-btn .btn:active {
    background-color: var(--main-color) !important;
    border: 1px solid var(--main-color) !important;
    box-shadow: inset 0px 0px 8px #00000059 !important;
    outline: none;
}

@media(max-width:767px) {
    .empty-page {
        background: #fff;
    }
    .empty-page .empty-content .empty-img img {
        width: 40px;
        height: 40px;
    }
    .empty-page .empty-content-description {
        font-size: 22px;
        line-height: 30px;
    }
}